
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { NavigationLink } from '@/helpers/mappers/mapper';

@Component({
  name: 'SidebarMenuItem',
})
export default class SidebarMenuItem extends Vue {
  @Prop({ required: true })
  private readonly item!: NavigationLink;

  @Prop({ default: false })
  private readonly text!: boolean;

  async isEllipsisActive(): Promise<boolean> {
    await this.$nextTick();
    const element = this.$refs.title as HTMLElement;

    return (element.offsetWidth < element.scrollWidth);
  }
}
