import {
  Mapper,
  NavigationItem as Item,
  NavigationInput as Input,
  NavigationLink as Link,
  NavigationSidebarHeader as Header,
} from '@/helpers/mappers/mapper';

export { Link, Header };

export default class SidebarMenuMapper implements Mapper {
  public input: Input | undefined;

  public items: Array<Link|Header> = [];

  public map(): void {
    this.translateNav();
  }

  private translateNav() {
    this.items = [];

    if (this.input instanceof Array) {
      this.input.forEach((item: Item) => {
        // Add header
        this.items.push(SidebarMenuMapper.translateHeader(item));

        // Add items if there are any
        if (item.items !== undefined) {
          item.items.forEach((subItem: Item) => {
            this.items.push(SidebarMenuMapper.translateMenuItem(subItem));
          });
        }
      });
    }
  }

  private static translateHeader(item: Item): Header {
    return {
      header: item.label,
      icon: item.metadata?.icon ?? 'mdi-minus',
    };
  }

  private static translateMenuItem(subItem: Item): Link {
    const link: Link = {
      title: subItem.label,
      to: subItem.url,
      hasCounter: subItem.metadata?.list_counter,
    };

    if (link.hasCounter) {
      link.total = subItem.metadata?.total as number;
    }

    return link;
  }
}
