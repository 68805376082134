
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

@Component({
  name: 'SidebarCaption',
})
export default class SidebarCaption extends Vue {
  @Prop({ required: true })
  private readonly item!: { [key: string]: string };
}
